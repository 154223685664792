import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const schema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Email is required'),
    password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});

export default function Login() {
      const navigate = useNavigate();
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = async (data) => {
        try {
          const response = await axios.post('https://api.webxgames.com/admin/admindetail', data);
            if (response.data.status === 200) {
                toast.success("Login successful!");
                setTimeout(() => {
                  navigate('/ContactUs');
                }, 2000); 
              }
          
        } catch (error) {
          if (error.response) {
            console.error('Error response data:', error.response.data);
            console.error('Error response status:', error.response.status);
            console.error('Error response headers:', error.response.headers);
          } else if (error.request) {
            console.error('Error request:', error.request);
          } else {
            console.error('Error message:', error.message);
          }
        }
      };

    return (
        <div>
            <ToastContainer></ToastContainer>
            <section className="vh-100" style={{ backgroundColor: "#9A616D" }}>
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col col-xl-10">
                            <div className="card" style={{ borderRadius: "1rem" }}>
                                <div className="row g-0">
                                    <div className="col-md-6 col-lg-5 d-none d-md-block">
                                        <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"
                                            alt="login form" className="img-fluid" style={{ borderRadius: "1rem 0 0 1rem" }} />
                                    </div>
                                    <div className="col-md-6 col-lg-7 d-flex align-items-center">
                                        <div className="card-body p-4 p-lg-5 text-black">

                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div className="d-flex align-items-center mb-3 pb-1">
                                                    <i className="fas fa-cubes fa-2x me-3" style={{ color: "#ff6219" }}></i>
                                                    <span className="h1 fw-bold mb-0">Gtron Admin</span>
                                                </div>

                                                <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: "1px" }}>Sign into your account</h5>

                                                <div className="form-outline mb-4">
                                                    <Controller
                                                        name="email"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <input type="email" id="form2Example17" className={`form-control form-control-lg ${errors.email ? 'is-invalid' : ''}`} {...field} />
                                                        )}
                                                    />
                                                    <label className="form-label" htmlFor="form2Example17">Email address</label>
                                                    {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                                                </div>

                                                <div className="form-outline mb-4">
                                                    <Controller
                                                        name="password"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <input type="password" id="form2Example27" className={`form-control form-control-lg ${errors.password ? 'is-invalid' : ''}`} {...field} />
                                                        )}
                                                    />
                                                    <label className="form-label" htmlFor="form2Example27">Password</label>
                                                    {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
                                                </div>

                                                <div className="pt-1 mb-4">
                                                    <button data-mdb-button-init data-mdb-ripple-init className="btn btn-dark btn-lg btn-block" type="submit">Login</button>
                                                </div>
                                                <a className="small text-muted" href="#!">Forgot password?</a>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
