import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

export default function ContactUs() {
    const [getdataform, setGetdataform] = useState([]);
console.log(getdataform)
    const getData = async () => {
        try {
            const response = await axios.get("https://api.webxgames.com/admin/getContactForm");
            const dataWithSelection = response.data.data.map(item => ({
                ...item,
                isSelected: false
            }));
            setGetdataform(dataWithSelection);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const handleCheckboxChange = (rowId) => {
        const updatedData = getdataform.map(row =>
            row.id === rowId ? { ...row, isSelected: !row.isSelected } : row
        );
        setGetdataform(updatedData);
    };

    const columns = [
        {
            name: 'firstname',
            selector: row => row.firstname,
            sortable: true,
        },
        {
            name: 'lastname',
            selector: row => row.lastname,
            sortable: true,
        },
        {
            name: 'email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'mobilenumber',
            selector: row => row.mobilenumber,
            sortable: true,
        },
        {
            name: 'state',
            selector: row => row.state,
            sortable: true,
        },
        {
            name: 'city',
            selector: row => row.city,
            sortable: true,
        },
        {
            name: 'country',
            selector: row => row.country,
            sortable: true,
        },
        {
            name: 'walletaddress',
            selector: row => row.walletaddress,
            sortable: true,
        },
        {
            name: 'Select',
            cell: row => (
                <input
                    type="checkbox"
                    checked={row.isSelected}
                    onChange={() => handleCheckboxChange(row.id)}
                />
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Status',
            selector: row => row.walletaddress,
            sortable: true,
        },
    ];

    return (
        <div>
            <DataTable
                title="Contact List"
                columns={columns}
                data={getdataform}
                pagination
                selectableRows
                persistTableHead
            />
        </div>
    );
}
